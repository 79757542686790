import React from 'react';
import ReactGA from "react-ga";

function Intro() {
	return (
		<div className="Intro-card-decades Intro-text">
			<p><span className="first-line">The 2010s saw drastic changes</span>  in both music itself and the way we consume it. If MP3s defined the 2000s, the 2010s were instead dominated by streaming. The audio passing through our earbuds was once owned, but is now borrowed. Does this make for a more shallow connection to music? It might. But also, music of all colors and stripes and genres and decades is now, remarkably, at our fingertips, available at the click of a button. This ability not only broadened listeners’ tastes, but it influenced the artists creating music.</p>
			<p>Genre no longer has strict boundaries like it did at the turn of the decade. It used to be easy to determine whether a song was ‘rock’ or ‘pop’ or ‘R&B’ or ‘rap’ or ‘indie’ (and as nebulous as the term ‘indie’ is, you still knew it when you heard it). Now, a song can incorporate all those genres and more, which is why the term ‘monogenre’ gained a foothold this decade.</p>
			<p>Not only did genre lines blur, but previously disparate scenes of music fans -- the indie kids, the punks, the hip-hop heads, the top-40 fans, etc. -- began to cross over. Back in the age of purchasing music, you had to be judicious about what music deserved your limited amount of money. As you became a fan of certain genre, it was likely that you would slowly continue to buy albums in that genre and burrow deeper into it. Now that you can literally listen to anything, on demand, on Spotify or YouTube or Soundcloud, it’s more natural to become interested and well-versed in music across the spectrum. Why devote yourself to a certain scene when you can effortlessly traverse any path of discovery you want?</p>
			<p>Though you could argue that many listeners’ connection to music became more ephemeral and low-stakes this decade, I would contend that the current phenomena shaping our auditory experiences also led to a boom in exciting, innovative, cathartic, transcendent music, the best of which is contained below.</p>
			<p>I maniacally committed to a list of the 200 best songs of the decade, which was truly insane. But I can’t bear to not recognize all 200 of these songs. In fact, here are <a target="_blank" rel=" noreferrer noopener" href="https://docs.google.com/document/d/1FlyaUxgsDBALRaNBACm1tKzYwXzzTuDL1RDG_lHnKSI/edit?usp=sharing">just over 100 more songs that deserve honorable mention</a>.</p>
			<p>One note before proceeding: each entry includes the song's year of release, as well as its ranking on my own "best songs" list from that particular year. Since the list covers ten years, and if you assume that each year is equal in quality (<a target="_blank" rel="noreferrer noopener" href="https://res.cloudinary.com/ddvn6aemk/image/upload/v1577091082/best-songs-by-year.png">which it's not</a>, but let's assume), you would expect a #1 song on a previous year-end list to be somewhere in this list's top 10, a previous #2 song to be in the 11-20 range, a #3 song to be in the 21-30 range, etc. This framing helps us put each song's placement into context and see whether it increased or decreased in my estimation since it came out.</p>
			<p>Okay, let’s get to it. Scroll through, listen, and enjoy.</p>

			<div className="Playlist-group-decades">
				<p>Here are playlist links if you choose to stream these songs that way. The "Spotify Clean Playlist" has all the explicit songs removed, in case you want to shuffle it with young and/or sensitive ears around.</p>
				
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotify"
		        to="https://open.spotify.com/playlist/3GMvty3Dl4NVJI3zFFY9hF?si=l4t5WxYaRy6utevLsjVP4g"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify']}
		      >
		        <span>Spotify Playlist</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotifyClean"
		        to="https://open.spotify.com/playlist/6eb6ZLPHflYRArs7o8t1tC?si=vxhcE4ySQZSUDcfY9Khcxg"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify-clean']}
		      >
		        <span>Spotify Clean Playlist</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotifyTop"
		        to="https://open.spotify.com/playlist/7mMGAdBUTko7yzFjpQUR1m?si=ROrLL7CPSfafkU2Cqb6-pA"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify-top50']}
		      >
		        <span>Spotify Countdown of Top 50</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundYoutube"
		        to="https://www.youtube.com/watch?v=pFptt7Cargc&list=PLqpBLIvY2a6c5-1LWKhYTzijIjqWsTA_c&index=2&t=0s"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_youtube']}
		      >
		      	<span>YouTube Playlist</span>
		    	</ReactGA.OutboundLink>
		    </p>
			</div>

		</div>
	)
}

export default Intro;
