import React from 'react';

function Hero(props) {
	return (
		<div className="Hero__2020">
			<div className="Hero__album"></div>
			<div></div>
			<h1 className="sr-only">D-Brad Music's Best Songs of 2020</h1>
  		
			
			<div className="Hero__sound-selection-container twenty">
				<div className="Hero__sound-text twenty">Choose your sound preferences to continue:</div>
				<div className="sound-selection twenty sound-selection-yellow on" onClick={(e) => props.soundSelection(true, e)}>Let's hear it!</div>
				<div className="sound-selection twenty sound-selection-blue off" onClick={(e) => props.soundSelection(false, e)}>Mute for now</div>
			</div>
			<div className="imageCredit">Photo by <a target="_blank" rel="noopener noreferrer" href="https://www.pexels.com/@maksgelatin?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels
">Maksim Goncharenok</a> from <a target="_blank" rel="noopener noreferrer" href="https://www.pexels.com/photo/white-paper-on-red-textile-5994866/?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels">Pexels</a>.</div>
		</div>
	)
}

export default Hero;
