import React, { Component } from 'react';
import Nav from '../../RootComponents/Nav';
import Hero from './Hero';
import Intro from './Intro';
import SongCard from './SongCardDecades';
import PlayerDecades from './PlayerDecades';
import Footer from '../../RootComponents/Footer';
import { Event } from "../../Tracking";

import songData2010s from '../data/songData2010s.json';

const menuObj = { //disabling for now until the full site is integrated
	"title": "Menu",
	"links": [
		{
			"url": "/",
			"linkTitle": "Home"
		},
		{
			"url": "/2017",
			"linkTitle": "2017"
		},
		{
			"url": "https://www.dbradmusic.com",
			"linkTitle": "dbradmusic.com"
		}
	]
}

class TwentyTens extends Component {
	constructor(props) {
		super(props);
				this.songCardRef = React.createRef();

		this.state = {
			songData: {songData2010s},
			soundOn: null, 
			songId: 200,
			songUrl: '',
			playing: false,
			startTime: 0,
			endTime: 0,
			inViewport: false,
			width: 0,
			height: 0,
			getElement: null
		}
	}

	componentDidMount() {
		// ReactGA.initialize('UA-154952412-1');
		// Event("PRODUCT", "Product added to cart", "PRODUCT_PAGE")

		Event("DECADES", "Landed on decades list main (2010)", "DECADES_2010_PAGE")

		this.updateWindowDimensions();
	  window.addEventListener('resize', this.updateWindowDimensions); 
	  this.setState(() => {
      return {
        getElement: document.getElementById("videoInView_decades")
      };
    });
	};

	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
	  this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	
	initialSoundSelection = (val) => { // set & update user sound preferences
		this.handlePlaying();
		this.setState( 
			{ soundOn: val },
			() => window.scrollTo({ // add callback so function can access updated state
	      top:this.songCardRef.current.offsetTop, 
	      behavior: "smooth"   
	    }))
	}

	toggleSound = (val) => {
		this.setState({
			soundOn: !val,
		})
	}

	_onChange = (isVisible, param) => {
    if (isVisible) {
    	this.setVideoUrl(param)
    	this.setState({ songId: param, inViewport: isVisible })
    } else {
    	this.isInViewport(isVisible)
    	//this.setState({ inViewport: isVisible })
    }
	};

	setVideoUrl = (id) => {
		const currentSongObj = songData2010s.filter(song => parseInt(song._id) === id);
		const currentSongUrl = currentSongObj[0].videoURL;
		const currentSongStart = parseInt(currentSongObj[0].start);
		const currentSongEnd = parseInt(currentSongObj[0].end);
		this.setState({ 
			songUrl: currentSongUrl,
			startTime: currentSongStart,
			endTime: currentSongEnd
		})
	}

	isInViewport = (visibility) => {
		this.setState({ inViewport: visibility })
	 }


	handlePlaying = () => {
		this.setState({
			playing: true
		})
	}

	render() {
		const data = this.state.songData.songData2010s;
		const listSoundStatus = (this.state.soundOn === null) ? "Main hidden" : "Main";
		const locked = (this.state.soundOn === null) ? "fix-position" : "";
		const width = this.state.width;
		const isVisible = this.state.inViewport;
		const soundStatus = (this.state.soundOn === null) ? "hidden" : " ";
		const soundChoice = this.state.soundOn
		const currentSongObj = data.filter(song => parseInt(song._id) === this.state.songId);
		const times = [this.state.startTime, this.state.endTime];

		const containmentDOMRect = this.state.getElement
      ? this.state.getElement
      : null;

		return (
			<div className="App-decades">																								
				<Nav 
					menu={menuObj} 
					width={width} 
					visibility={soundStatus}
					soundChoice={soundChoice}
					toggleSound={() => this.toggleSound(soundChoice)}
					muted={!soundChoice} />
				<Hero soundSelection={this.initialSoundSelection} locked={locked}/>
				<div ref={this.songCardRef}></div>

				<div className={listSoundStatus}>
					<section className="Intro-container">
						<Intro />	
					</section>
					<section className="List-container_decades" > 

						<div className="col col-left" >
						<div className="Jump-nav">
							<a href="#200">200&ndash;101</a>
							<a href="#100">100&ndash;51</a>
							<a href="#50">50&ndash;1</a>
						</div>
						<div id="videoInView_decades" style={{opacity:1}}></div>
							{data.map((song) => {
							
							return (
										
										<SongCard 
											song={song} 
											key={song._id} 
											onChange={isVisible => this._onChange(isVisible, parseInt(song._id))} 
											containment={containmentDOMRect} /> 
								)
							})}
						</div>
						<div className="col col-right">
							<PlayerDecades 
								songId={this.state.songId} 
								currentSong={currentSongObj}
								songData={data} 
								videoUrl={this.state.songUrl} 
								isVisible={isVisible} 
								volume={0.5} 
								width={width} 
								muted={!soundChoice} 
								times={times} />
						</div>
					</section>
				</div>
				<Footer visibility={soundStatus} />
			</div>
		)
	}
}

export default TwentyTens;