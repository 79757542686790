import React from 'react';
import ReactGA from "react-ga";

function Intro() {
	return (
		<div className="Intro-card-decades Intro-text-twenty">
			<p>Call me unoriginal, but it's impossible to talk about 2020 without talking about the global pandemic. Our lives were turned completely upside down, in a way that none of us could have foreseen or even imagined. Many of our previous comforts, whether they were taken for granted or earned and appreciated, were stripped away from us. Luckily, one comfort that remained throughout was music.</p>
			<p>2020 was a banner year for music. Quarantine creations from Taylor Swift and Charli XCX. Vibrant rap from Freddie Gibbs, Megan Thee Stallion, and Black Thought. Shimmering rock music from HAIM and Rolling Blackouts Coastal Fever. Returns to form from indie mainstays like Fleet Foxes, Dirty Projectors, the Strokes, and the Killers. Pop dominance from The Weeknd, Dua Lipa, and Christine and the Queens. Warm, southern-fried comfort-food rock from Waxahatchee and Kevin Morby. Wondrous indie odysseys from those young (Phoebe Bridgers) and old (sorry, Sufjan). Vital, edge-of-your-seat rock from lesser known newcomers like Fontaines D.C., Kiwi Jr., Loose Buttons, and Mourn. Genre-less gems from Yves Tumor, Tame Impala, SAULT, and the Avalanches. And covers — so many covers! A jazz-soul cover of Radiohead, a modern R&B take on Nirvana, a faithfully gorgeous cover of Big Star, and an updated spin on traditional folk music.</p>
			<p>The last time we had new music this consistently great was from 2015 to 2017 — another time of transition and upheaval. There must be something in the air.</p>

			<h3 className="twenty">Honorable Mentions</h3>
			<ul>
				<li>Jamie xx: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=BpSPffYTvN4">Idontknow</a></li>
				<li>Car Seat Headrest: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=ve1yeirllcY">Martin</a></li>
				<li>Laura Marling: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=dLL1KAc18hs">Held Down</a></li>
				<li>Kurt Vile: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=Haj5s283eis">Dandelions</a></li>
				<li>Silverbacks: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=SHKiEgBx20s">Muted Gold</a></li>
				<li>Skullcrusher: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=8jH8G-FewZI">Day of Show</a></li>
				<li>Grimes: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=gvzC8MmC850">Delete Forever</a></li>
				<li>Pure X: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=rzHRYBVGK5Y">Hollywood</a></li>
				<li>Widowspeak: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=V6fARScTp7c">Money</a></li>
				<li>Tasha: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=q19EXdJJiww">But There's Still the Moon</a></li>
				<li>My Morning Jacket: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=WFl0Kotmj7U">Feel You</a></li>
				<li>Kelly Lee Owens ft. John Cale: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=RKGbveD_cuE">Corner of My Sky</a></li>
				<li>Westside Gunn ft. Joey Bada$$ & Tyler, the Creator: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=kmhgibIoJj0">327</a></li>
				<li>Chloe x Halle: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=a9HIaGcBocc">Do It</a></li>
				<li>Lady Gaga ft. Ariana Grande: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=AoAm4om0wTs">Rain On Me</a></li>
			</ul>

			<div className="Playlist-group-twenty">
				<h3 className="twenty">Playlist Links</h3>				
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotify"
		        to="https://open.spotify.com/playlist/4iNmI4nq24Tes6OL0Y2lTr?si=WByltDWPQtGx4x5cPjvb7g"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify']}
		      >
		        <span>Spotify Playlist</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotifyClean"
		        to="https://open.spotify.com/playlist/5el8RYGfVtVk5qTH4VL9cY?si=ftAHFya8SRqMe6ZJ7PN4fA"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify-clean']}
		      >
		        <span>Spotify Clean Playlist</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotifyTop"
		        to="https://open.spotify.com/playlist/3x4DyPfINOL70Thag8VTDJ?si=yw0xUpIKT3eybb1leYccIA"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify-top50']}
		      >
		        <span>Spotify Countdown (50 to 1)</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotifyTop"
		        to="https://open.spotify.com/playlist/1bMWgLfmcGEcTfWP6ukyvm?si=CR_GfxYmTAmegkvIvTTLBg"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify-expanded']}
		      >
		        <span>Spotify Expanded 100-Song Playlist</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundYoutube"
		        to="https://www.youtube.com/watch?v=mYHAnuBKV-o&list=PLqpBLIvY2a6dHX9G9KZ864shVHhTKB4E5"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_youtube']}
		      >
		      	<span>YouTube Playlist</span>
		    	</ReactGA.OutboundLink>
		    </p>
			</div>

		</div>
	)
}

export default Intro;
