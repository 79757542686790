import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import Eighteen from './2018/components/Eighteen.js';
import Decades from './decades/components/TwentyTens.js';
import TwentyTwenty from './2020/components/TwentyTwenty.js';
import TwentyOne from './2021/components/TwentyOne.js';
import Home from './Home.js';

//import ReactGA from "react-ga";
import {PageView, initGA} from './Tracking/index.js';

import './App.scss';

class App extends Component {
  componentDidMount() {
    initGA('UA-154952412-1');

    PageView();
  }

  render() {
    return (
      <div className="App App-landing">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          {/*<Route path="/2017" component={() => { window.location = 'http://dbradbestsongs2017.com'; return null;} }/>
          <Route exact path="/2018" component={Eighteen} />*/}
          <Route exact path="/decades" component={Decades} />
          <Route exact path="/2020" component={TwentyTwenty} />
          <Route exact path="/2021" component={TwentyOne} />
        </Switch>
      </BrowserRouter>
      </div>
    );
  }
}

export default App;