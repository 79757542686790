import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import VisibilitySensor from 'react-visibility-sensor';

function SongCard(props) {
	const _JSONdesc = props.song.description;
  const _parsedDesc = ReactHtmlParser(_JSONdesc);
  const _JSONArtist = props.song.artist;
  const _parsedArtist = ReactHtmlParser(_JSONArtist);
  const songTitle = props.song.title;
  const windowLoc = window.location.pathname;
  const folderName =  (windowLoc === '/2021') ? 'dbrad2021' : (windowLoc === '/2020') ? 'dbrad2020' : 'dbrad2010s';
  //let folderName = window.location.pathname === '/2020' ? 'dbrad2020' : 'dbrad2010s';
	const parsedSongTitle = songTitle.replace(/\//g, '\\').replace(/ *\([^)]*\) */g, "").replace(/[^\w\s]/gi, '').replace(/ /g,"-").replace(/[í]/g,"i");
	const imgLink = `https://res.cloudinary.com/ddvn6aemk/image/upload/c_limit,w_432/v1576823716/${folderName}/${parsedSongTitle}.jpg`

	return (

		<div className="Song-card-decades" id={props.song._id} >
			<VisibilitySensor key={props.song._id} onChange={props.onChange} containment={props.containment} partialVisibility={true} offset={{bottom: 40}} scrollDelay={50}>
				{({ isVisible }) => {
					return (
				
						<div className="card-decades">				  
						  <div className="card-col-1">
								<div className="h1">{props.song._id}</div>
								<img src={imgLink} alt={props.song.artist} />
							</div>

						  <div className="card-col-2">
						    <div className="title-text">
						      <h2 className="h3 font-weight-light">{_parsedArtist}</h2>
						      <h3 className="h4">{props.song.title}</h3>
						    </div>
						    <div className="details">
						    	<span>{props.song.year}</span>
						    	<span style={{padding: 0 + 15 + 'px'}}>
						    		<svg width="7" height="8"><circle cx="3" cy="3" r="2" fill="#8c8c8c"></circle>
						    		Sorry, your browser does not support inline SVG.
						    		</svg>
									</span>
									<span>Previous Year-End Ranking: {props.song.prevRank}</span>
						    </div>
						    <div className="description p">{_parsedDesc}</div>
						  	<div className="listen"><span className="p">Listen: </span><a href={props.song.listenURL} target="_blank" rel="noopener noreferrer">{props.song.artist}, {props.song.title}</a></div>
						  </div>
						</div>
					);
				}}
			</VisibilitySensor>
		</div>
	)
}

export default SongCard;