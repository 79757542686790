import React from 'react';
import ReactGA from "react-ga";


function Intro() {
	return (
		<div className="Intro-card-decades Intro-text-twenty">
			<p>Something unfortunate has been happening with me lately, where I’m finding it difficult to forge a deep connection with music. The mind-numbing pandemic is still a thing, so that could be a big reason. It has led to fewer opportunities to listen to music on walks and drives, taking in the seasonal surroundings to enhance the experience. It has also led to more time at home with a two-year-old that needs both my constant personal attention and control of all audio throughout the house (“Hey Google, play ‘Do-Re-Mi”; “Dada, don’t sing, don’t sing!”). Maybe I’m at a place in my life where the catharsis from a well-constructed song just doesn’t hit the same because I’m a fully grown human adult now. Or it could all be related to a sneaking suspicion that my ears are, physically, not picking up the nuances of different songs like they used to. I don’t know if that’s a function of growing older, or if my ears are just clogged all the time.</p>
			<p>But enough about my congestive status. I don’t think I’ve made a very compelling case to listen to these songs, but I wanted to take this opportunity to reflect back honestly on the state of my music listening experience. Here’s what the takeaway should be: the top 50 songs of 2021 were all excellent enough to break through that shell and still find ways to thrill, comfort, and move me.</p>
			<p>Due to aforementioned circumstances, like a pandemic and a toddler, I didn’t get a chance to write about all of the songs on this list. After scrolling through the usual video/audio snippets of #50 through #21, you will find written descriptions for the top 20.</p>

			<h3 className="twenty">Honorable Mentions</h3>
			<ul>
				<li>Dry Cleaning: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=JHlEkrsmxS4">Scratchcard Lanyard</a></li>
				<li>Megan Thee Stallion: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=1JJOyk0DJqM">Thot S**t</a></li>
				<li>SZA: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=2p3zZoraK9g">Good Days</a></li>
				<li>Golden Rules & Prefuse 73 ft. Freddie Gibbs & Yasiin Bey: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=mKVwzB7Wtsg">Never Die</a></li>
				<li>Fred Again.. ft. The Blessed Madonna: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=VgSkr08y8iE">Marea (We've Lost Dancing)</a></li>
				<li>Polo G: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=3YcJH9HzBd0">RAPSTAR</a></li>
				<li>Yves Tumor: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=Err7lDuvw-0">Crushed Velvet</a></li>
				<li>Hovvdy: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=L-RV9WiV81I">Junior Day League</a></li>
				<li>The War on Drugs: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=NHcR2Jb3eH0">Change</a></li>
				<li>The Killers: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=9UinEpwdGcA">Quiet Town</a></li>
				<li>Tems: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=1Kgoy05H-h0">Replay</a></li>
				<li>Geese: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=BvAlWXmy1iA">Disco</a></li>
				<li>Faye Webster: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=7oq-lvTXf3s">Cheers</a></li>
				<li>Yasmin Williams: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=wa_d4KvvORY">I Wonder (Song for Michael)</a></li>
				<li>Adele: <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=U3ASj1L6_sY">Easy On Me</a></li>
			</ul>

			<div className="Playlist-group-twenty twenty-one">
				<h3 className="twenty">Playlist Links</h3>				
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotify"
		        to="https://open.spotify.com/playlist/6Hp2JNv2Nrfzd2LyklDGSP?si=223e9c0e8f884da5"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify']}
		      >
		        <span>Spotify Playlist</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotifyClean"
		        to="https://open.spotify.com/playlist/62fsK9WmNdIhRXvc3U1x5H?si=5bfca21987654ee0"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify-clean']}
		      >
		        <span>Spotify Clean Playlist</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotifyTop"
		        to="https://open.spotify.com/playlist/3DgyhaBqeFFwS3x8zgymXF?si=e09ee8d4ebe443b5"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify-top50']}
		      >
		        <span>Spotify Countdown (50 to 1)</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundSpotifyTop"
		        to="https://open.spotify.com/playlist/6xgG418xNf7V7Mnqnz8f4G?si=305b54a513d54561"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_spotify-expanded']}
		      >
		        <span>Spotify Expanded 100-Song Playlist</span>
		      </ReactGA.OutboundLink>
				</p>
				<p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundYoutube"
		        to="https://www.youtube.com/watch?v=2ZfcZEIo6Bw&list=PLqpBLIvY2a6dgcicRn27NVK9K3FeQvEQg"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_youtube']}
		      >
		      	<span>YouTube Playlist</span>
		    	</ReactGA.OutboundLink>
		    </p>
		    <p className="h5">
					<ReactGA.OutboundLink
		        eventLabel="outboundYoutube"
		        to="https://www.youtube.com/watch?v=bn_p95HbHoQ&list=PLqpBLIvY2a6fp6JAzPiGOMM5uMlgaYIQ-"
		        target="_blank"
		        rel="noopener noreferrer"
		        trackerNames={['introLink_youtube']}
		      >
		      	<span>YouTube Countdown (50 to 1)</span>
		    	</ReactGA.OutboundLink>
		    </p>
			</div>

		</div>

	)
}

export default Intro;
