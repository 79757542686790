import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Home extends Component {
	render() {
		return (
			<div className="Landing">
				<a href="https://dbradmusic.com">dbradmusic.com</a>
				{/*<Link to="/2017">2017</Link>
				<Link to="/2018">2018</Link>*/}
				<Link to="/decades">Decades</Link>
				<Link to="/2020">2020</Link>
				<Link to="/2021">2021</Link>
			</div>		
		)
	}
}

export default Home;