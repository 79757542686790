import React from 'react';

function Hero(props) {
	return (
		<div className="Hero__2021">
			<div className="Hero__album"></div>
			<div></div>
			<h1 className="sr-only">D-Brad Music's Best Songs of 2021</h1>
  		
			
			<div className="Hero__sound-selection-container twenty-one">
				<div className="Hero__sound-text twenty">Choose your sound preferences to continue:</div>
				<div className="sound-selection twenty-one sound-selection__pastel-yellow" onClick={(e) => props.soundSelection(true, e)}>Let's hear it!</div>
				<div className="sound-selection twenty-one sound-selection__pastel-pink" onClick={(e) => props.soundSelection(false, e)}>Mute for now</div>
			</div>
		</div>
	)
}

export default Hero;
