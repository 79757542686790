import React from 'react';

function Footer(props) {
	const visibility = `${props.visibility} Footer-18 footer`
	return (
		<div className={visibility}>
			<div className="footer-links-container">
				<div className="footer-links-item listen">
					<h4>LISTEN</h4>
					<div className="footer-link-div"><a className="footer-link" href="https://open.spotify.com/playlist/3GMvty3Dl4NVJI3zFFY9hF?si=l4t5WxYaRy6utevLsjVP4g" target="_blank" rel="noopener noreferrer">Spotify</a></div>
          <div className="footer-link-div"><a className="footer-link" href="https://www.youtube.com/watch?v=-ed6UeDp1ek&list=TLGG42VTyowrm6AyMzEyMjAxOQ" target="_blank" rel="noopener noreferrer">YouTube</a></div>
				</div> 
				<div className="footer-links-item follow">
					<h4>FOLLOW</h4>
					<div className="footer-link-div"><a className="footer-link" href="https://dbradmusic.com" target="_blank" rel="noopener noreferrer">D-Brad Music</a></div>
          <div className="footer-link-div"><a className="footer-link" href="https://twitter.com/catchadave" target="_blank" rel="noopener noreferrer">Twitter</a></div>
				</div>
			</div>
			<div className="bottom-span">
				<span>Extreme listing by <a className="footer-link" href="https://dbradmusic.com" target="_blank" rel="noopener noreferrer">dbradmusic.com</a></span> | 
				<span> Site by <a className="footer-link" href="http://taylor-parsell.com" target="_blank" rel="noopener noreferrer">taypsl</a></span> | 
				<span> Made with <span aria-label="face with medical mask" role="img">😷 </span> + <span aria-label="doggo" role="img">🐕 </span> + <span aria-label="babe" role="img">👶 </span> in Berkeley, CA ©2019</span>
			</div>
		</div>
	)
}
export default Footer;