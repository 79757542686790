import React from 'react';
import Logo from '../images/logo-sm.svg';
//import Navburger from './Navburger';
import soundOn from '../images/on.svg';
import soundOff from '../images/off.svg';
import ReactGA from "react-ga";

function Nav(props) {
	const soundIcon = (props.soundChoice) ? soundOn : soundOff ; 
	const soundVisibility = (props.visibility === "hidden") ? "sound-icon hidden" : "sound-icon" ;
	const checkMobile = (props.width <= 768) ? 'hidden' : ''; 

	return (
		<div className="Nav-18">
			<div className="Nav-18-left">
				<div className="Logo-18">
					<ReactGA.OutboundLink eventLabel="Logo" to="/" target="_self">
						<img src={Logo} alt="logo"/>
					</ReactGA.OutboundLink>
				</div>
				<div className="Nav-title"></div>
			</div>
			<div className="Nav-18-right">
				<div className={props.visibility}>
					<div className={checkMobile}>
					</div>
				</div>
				<img className={soundVisibility} alt="volume toggle" src={soundIcon} onClick={props.toggleSound} />
				{/*<Navburger menu={props.menu} />*/}
			</div>
		</div>
	)
}

export default Nav;