import React from 'react';

function Hero(props) {
	return (
		<div className="Hero__decades">
			<div className="Hero__album">

			</div>
			<div className="Hero__title-wrapper">
					<h1>
						<span className="outline">the </span>
						<span className="yellow">200 </span>
						<span>BEST </span>
						<span>SONGS </span>
						<span className="outline">of the </span>
						<span className="yellow">2010s</span>
					</h1>
			</div>
  		
			<h2 className="h4 Hero__sound-text">Choose your sound preferences to continue:</h2>
			<div className="Hero__sound-selection-container">
				<div className="sound-selection on" onClick={(e) => props.soundSelection(true, e)}>Let's hear it!</div>
				<div className="sound-selection off" onClick={(e) => props.soundSelection(false, e)}>Mute for now</div>
			</div>
		</div>
	)
}

export default Hero;
